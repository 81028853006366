import { useEffect } from 'react';

function MainRedirect() {
  useEffect(() => {
    // public 폴더 안에 있는 main.html로 리다이렉트
    window.location.href = '/main.html';
  }, []);

  return (
    <div>Redirecting...</div>
  );
}

export default MainRedirect;