import React, { useEffect } from 'react';
import { Box, Button, Text, VStack } from "@chakra-ui/react";
import useUser from "../lib/useUser";

export default function Home() {
  const { isLoggedIn, user } = useUser();

  useEffect(() => {
    document.title = "로그인 페이지";
    return () => {
      document.title = "React App"; // 이전 타이틀로 되돌리기
    };
  }, []);

  const handleSystemEntry = () => {
    if (isLoggedIn) {
      window.location.href = "https://dsprojectforland.xyz/main.html";
    }
  };

  return (
    <Box
      position="absolute"
      top="85px"
      left={0}
      width="100vw"
      height="calc(100vh - 85px)"
      bgImage="url('/loginbg.png')"
      backgroundSize="cover"
      backgroundPosition="center"
      backgroundRepeat="no-repeat"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      {isLoggedIn && user ? (
        <VStack spacing={4}>
          <Text fontSize="35px" fontWeight="bold">{user.name}님, 환영합니다.</Text>
          <Button colorScheme="blue" size="lg" fontSize="lg" mt={5} onClick={handleSystemEntry}>
            시스템 입장
          </Button>
        </VStack>
      ) : (
        <Text fontSize="30px" fontWeight="bold">로그인이 필요합니다.</Text>
      )}
    </Box>
  );
}

