import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  FormControl,
  FormLabel,
  Input,
  useToast,
} from "@chakra-ui/react";
import { useState } from "react";
import { logOut } from "../api";

interface ChangePasswordModalProps {
  isOpen: boolean;
  onClose: () => void;
}

// CSRF 토큰을 쿠키에서 읽어오는 함수
function getCookie(name: string): string | null {
  let cookieValue = null;
  if (document.cookie && document.cookie !== '') {
    const cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      if (cookie.substring(0, name.length + 1) === (name + '=')) {
        cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
        break;
      }
    }
  }
  return cookieValue;
}

export default function ChangePasswordModal({ isOpen, onClose }: ChangePasswordModalProps) {
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const toast = useToast();

  const handleChangePassword = async () => {
    if (newPassword !== confirmNewPassword) {
      toast({
        title: "비밀번호가 일치하지 않습니다",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    try {
      const csrftoken = getCookie('csrftoken'); // CSRF 토큰 가져오기

      // 헤더 객체 생성
      const headers: HeadersInit = new Headers();
      headers.set('Content-Type', 'application/json');
      if (csrftoken) {
        headers.set('X-CSRFToken', csrftoken);
      }

      const response = await fetch("https://backend.dsprojectforland.xyz/api/users/change-password", {
        method: "PUT",
        headers: headers,
        credentials: "include",
        body: JSON.stringify({
          old_password: oldPassword,
          new_password: newPassword,
        }),
      });

      if (response.ok) {
        toast({
          title: "비밀번호 변경 성공",
          status: "success",
          duration: 5000,
          isClosable: true,
        });

        // 비밀번호 변경 성공 후 로그아웃 처리
        await logOut();
        onClose(); // 모달 닫기
      } else {
        throw new Error("비밀번호 변경 실패");
      }
    } catch (error: unknown) {
      const errorMessage = error instanceof Error ? error.message : "Unknown error";
      toast({
        title: "오류 발생",
        description: errorMessage,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>비밀번호 변경</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormControl mt={4}>
            <FormLabel>현재 비밀번호</FormLabel>
            <Input type="password" value={oldPassword} onChange={(e) => setOldPassword(e.target.value)} />
          </FormControl>
          <FormControl mt={4}>
            <FormLabel>새 비밀번호</FormLabel>
            <Input type="password" value={newPassword} onChange={(e) => setNewPassword(e.target.value)} />
          </FormControl>
          <FormControl mt={4}>
            <FormLabel>새 비밀번호 확인</FormLabel>
            <Input type="password" value={confirmNewPassword} onChange={(e) => setConfirmNewPassword(e.target.value)} />
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={handleChangePassword}>
            변경하기
          </Button>
          <Button variant="ghost" onClick={onClose}>취소</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
