import { FaMoon, FaSun } from "react-icons/fa";
import DuksungLogo from './duksung_logo.png'; // 라이트 모드 이미지 임포트
import DuksungLogo_white from './duksung_logo_white.png'; // 다크 모드 이미지 임포트
import {
  Avatar,
  Box,
  Button,
  HStack,
  IconButton,
  LightMode,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
  ToastId,
  useColorMode,
  useColorModeValue,
  useDisclosure,
  useToast,
  Text,
  Flex,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import LoginModal from "./LoginModal";
import SignUpModal from "./SignUpModal";
import useUser from "../lib/useUser";
import { logOut } from "../api";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useRef } from "react";
import ChangePasswordModal from "./ChangePasswordModal"; // 비밀번호 변경 모달 컴포넌트 임포트

export default function Header() {
  // 로그인 및 회원가입 모달 상태 관리
  const { userLoading, isLoggedIn, user } = useUser();
  console.log(user?.avatar_url)
  const {
    isOpen: isLoginOpen,
    onClose: onLoginClose,
    onOpen: onLoginOpen,
  } = useDisclosure();
  const {
    isOpen: isSignUpOpen,
    onClose: onSignUpClose,
    onOpen: onSignUpOpen,
  } = useDisclosure();
  const {
    isOpen: isChangePasswordOpen,
    onClose: onChangePasswordClose,
    onOpen: onChangePasswordOpen,
  } = useDisclosure();

  // 다크모드 토글 및 현재 모드에 따른 로고 이미지 선택
  const { toggleColorMode } = useColorMode();
  const logoImage = useColorModeValue(DuksungLogo, DuksungLogo_white);
  const Icon = useColorModeValue(FaMoon, FaSun);
  const toast = useToast();
  const queryClient = useQueryClient();
  const toastId = useRef<ToastId>();
  const mutation = useMutation(logOut, {
    onMutate: () => {
      toastId.current = toast({
        title: "Login out...",
        description: "Sad to see you go...",
        status: "loading",
        position: "bottom-right",
      });
    },
    onSuccess: () => {
      if (toastId.current) {
        queryClient.refetchQueries(['me']);
        toast.update(toastId.current, {
          status: "success",
          title: "로그아웃 되었습니다.",
          description: "안녕히 가세요.",
        });
      }
    },
  });
  const onLogOut = async () => {
    mutation.mutate();
  }

  return (
    <Stack
      justifyContent={"space-between"}
      alignItems="center"
      py={2}
      px={30}
      direction={{
        sm: "column",
        md: "row",
      }}
      spacing={{
        sm: 4,
        md: 0,
      }}
      borderBottomWidth="2px" // 테두리 굵기 설정
      borderBottomColor="black" // 테두리 색상 설정
      borderBottomStyle="solid" // 테두리 스타일 설정
      // bgImage="url('/loginbg.png')" // 배경 이미지 추가
      backgroundSize="cover" // 배경 이미지 크기 조절
    >
      <Flex alignItems="center">
        <Box>
          <Link to={"/"}>
            <img src={logoImage} alt="덕성학원 로고" width="70" />
          </Link>
        </Box>
        <Text fontSize="xx-large" fontWeight="bold" px={3}>
            학교법인 덕성학원 기본재산(토지) 관리 시스템
        </Text>
      </Flex>
      <HStack spacing={2}>
        <IconButton
          onClick={toggleColorMode}
          variant={"ghost"}
          aria-label="Toggle dark mode"
          icon={<Icon />}
        />
        {!userLoading ? (
          !isLoggedIn ? (
            <>
              <Button onClick={onLoginOpen}>Log in</Button>
              <LightMode>
                <Button onClick={onSignUpOpen} colorScheme={"red"}>
                  Sign up
                </Button>
              </LightMode>
            </>
          ) : (
              <Menu>
              <MenuButton>
                <Avatar name={user?.name} src={user?.avatar_url} size={"md"} />
              </MenuButton>
              <MenuList>
                <MenuItem onClick={onChangePasswordOpen}>비밀번호 변경</MenuItem>
                <MenuItem onClick={onLogOut}>로그아웃</MenuItem>
              </MenuList>
            </Menu>
          )
        ) : null}
      </HStack>
      <LoginModal isOpen={isLoginOpen} onClose={onLoginClose} />
      <SignUpModal isOpen={isSignUpOpen} onClose={onSignUpClose} />
      <ChangePasswordModal isOpen={isChangePasswordOpen} onClose={onChangePasswordClose} />
    </Stack>
  );
}
